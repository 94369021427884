import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';

if (PUBLIC_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://a1d94e2daa9ceb509a485d6eb07ccc75@o4506936015323136.ingest.us.sentry.io/4506936017223680',

		tunnel: '/api/sentry',

		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [replayIntegration()],

		environment: PUBLIC_ENV
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
